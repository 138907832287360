import React from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ErrorPage from 'pages/ErrorPage';
import { strings } from 'common';

export default () => (
  <ErrorPage
    errorKey={strings.notFound}
    descriptionKey={strings.requestedCatalogDoesNotExist}
    icon={<NotInterestedIcon color="disabled" fontSize="large" />}
  />
);

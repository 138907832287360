import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import * as en from './translations/en';
import * as bg from './translations/bg';

i18n
  .use(LngDetector)
  .init({
    resources: {
      en,
      bg,
    },
    fallbackLng: 'bg',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { strings } from 'common';
import { Grid } from '@material-ui/core';

const ToggleSortButtons = ({ t, sortBy, setSortBy }) => (
  <Grid item>
    {' '}
    <Typography
      component="span"
      color="textSecondary"
      style={{ marginRight: 10 }}
    >
      {t(strings.sortBy)}
    </Typography>
    <ToggleButtonGroup
      color="primary"
      value={sortBy}
      exclusive
      onChange={(e, value) => setSortBy(value)}
      style={{ height: '100%' }}
    >
      <ToggleButton value="name" style={{ padding: '14.75px' }}>
        {t(strings.sortByName)}
      </ToggleButton>
      <ToggleButton value="newest">{t(strings.sortByDate)}</ToggleButton>
    </ToggleButtonGroup>
  </Grid>
);

export default ToggleSortButtons;

import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export default ({ message }) => (
  <Box
    width={1}
    height={1}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
    <br />
    <Typography variant="body2" color="textSecondary">
      {message}
    </Typography>
  </Box>
);

import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createTheme } from '@material-ui/core';
// import { ThemeProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/styles';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'common';
import CatalogPage from 'pages/CatalogPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Rubik',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
});

const App = () => (
  <div className="App">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Switch>
            <Route path="/catalogs/:shortCode/" component={CatalogPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </div>
);

export default App;

import React from 'react';
import debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { TextField, Grid } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import { strings } from 'common';
import ToggleSortButtons from 'components/ToggleSortButtons';

const useStyles = makeStyles(theme => ({
  filterBar: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderTopWidth: theme.spacing(1),
    borderTopColor: theme.palette.grey[300],
    flexWrap: 'wrap-reverse',
  },
  formWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sortFilterGrid: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'end',
    },
  },
  sortButtons: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  filterField: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}));

export default ({ t, onChange, sortBy, setSortBy }) => {
  const classes = useStyles();
  const dOnFilterChange = debounce(onChange, 500);
  return (
    <Box px={4} py={2} className={classes.filterBar} id="start">
      <Grid container className={classes.sortFilterGrid}>
        <Grid item xs="auto" className={classes.sortButtons}>
          <ToggleSortButtons sortBy={sortBy} setSortBy={setSortBy} />
        </Grid>
        <Grid item xs="auto" sm="auto" className={classes.formWrapper}>
          <form className={classes.filterField} noValidate autoComplete="off">
            <TextField
              id="standard-search"
              label={t(strings.search)}
              type="search"
              margin="none"
              variant="outlined"
              className={classes.filterField}
              onChange={event => dOnFilterChange(event.target.value)}
              style={{ width: '100%' }}
              InputProps={{
                endAdornment: <FilterList />,
              }}
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CatalogItem from 'components/CatalogItem';

const CatalogGrid = ({ items, currency, showLightbox }) => (
  <Box p={4} pt={0}>
    <Grid container spacing={2}>
      {
        items.map(item => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} align="center" key={item.id}>
            <CatalogItem
              item={item}
              currency={currency}
              onClick={el => showLightbox(el.imgUrl)}
            />
          </Grid>
        ))
      }
    </Grid>
  </Box>
);

export default CatalogGrid;

import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { strings } from 'common';

const useStyles = makeStyles(theme => ({
  footer: {
    bottom: 0,
    position: 'relative',
    zIndex: -1,
    height: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  footerText: {
    color: theme.palette.common.white,
  },
}));

export default props => {
  const { t } = props;
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Typography className={classes.footerText}>
        {t(strings.hostedByOnstock)}
      </Typography>
    </Box>
  );
};

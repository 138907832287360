import React, { useEffect, useState } from 'react';
import { Fab, makeStyles, Zoom } from '@material-ui/core';
import debounce from 'lodash/debounce';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

let lastY = 0;

export default () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const listenToScroll = () => {
    const yOffset = window.pageYOffset;
    const percentY = lastY / yOffset;
    const smallScrollUp = percentY > 1 && percentY < 2;
    setVisible(yOffset > 100 && smallScrollUp);
    lastY = yOffset;
  };

  const debouncedListenToScroll = debounce(listenToScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', debouncedListenToScroll);
    return () => window.removeEventListener('scroll', debouncedListenToScroll);
  });

  return (
    <Zoom in={visible}>
      <Fab
        aria-label="up"
        className={classes.button}
        disableRipple
        color="secondary"
        href="#start"
      >
        <ArrowUpwardOutlined fontSize="large" />
      </Fab>
    </Zoom>
  );
};

import React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Avatar, Typography } from '@material-ui/core';
import headerBg from 'img/dot-grid.png';
import serviceUrl from 'util/url';
import { strings } from 'common';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundImage: `url(${headerBg})`,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: theme.palette.secondary.main,
  },
  headerText: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

const CatalogHeader = ({ t, firm }) => {
  const classes = useStyles();
  const {
    name,
    bio = t(strings.defaultBio),
    address,
    phone,
    email,
  } = firm;
  return (
    <Box p={4} className={classes.header}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm="auto" align="center">
          <Avatar
            className={classes.avatar}
            elevation={4}
            src={serviceUrl({ service: 'STATIC', path: get(firm, 'imgUrl'), useDefaultImg: false })}
          >
            {get(name, 0)}
          </Avatar>
        </Grid>
        <Grid container sm item spacing={2} className={classes.headerText}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>
                {name}
              </b>
            </Typography>
          </Grid>
          {
            bio && (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {bio}
                </Typography>
              </Grid>
            )
          }
          {
            address && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  <LocationOnOutlinedIcon className={classes.icon} />
                  {address}
                </Typography>
              </Grid>
            )
          }
          {
            phone && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  <CallOutlinedIcon className={classes.icon} />
                  <a href={`tel:${phone}`}>{phone}</a>
                </Typography>
              </Grid>
            )
          }
          {
            email && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  <EmailOutlinedIcon className={classes.icon} />
                  <a href={`mailto:${email}`}>{email}</a>
                </Typography>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default CatalogHeader;

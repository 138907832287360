import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Box, CardActionArea, CardMedia } from '@material-ui/core';
import serviceUrl from 'util/url';
import { strings } from 'common';
import { formatPrice } from 'common/formatter';
import promotionBadge from 'img/percent-badge.svg';

const useStyles = makeStyles(theme => ({
  media: {
    height: 300,
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  promotionBadge: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 48,
    height: 48,
  },
}));

const getQuantityText = (t, item) => {
  const q = get(item, 'warehouseQtys.0.qty');
  if (q === undefined) {
    return t(strings.available);
  }
  return Math.max(0, q);
};

const CatalogItem = ({
  t, item, onClick, currency,
}) => {
  const classes = useStyles();
  const price = get(item, 'offers.0.price');
  const isPromotion = item.isPromotion || item.offers.some(offer => offer.isPromotion);

  return (
    <Box display="inline-block" width={1} height={1}>
      <Card>
        <CardActionArea onClick={() => onClick(item)}>
          {/* <Box className={classes.media}>
            <ContainerDimensions>
            {({ width, height }) => (
              <Image
              src={serviceUrl('STATIC', get(item, 'imgUrl'))}
              title={get(item, 'name')}
              style={{ objectFit: 'cover' }}
              height={height}
              width={width}
              />
              )}
              </ContainerDimensions>
            </Box> */}
          {
            isPromotion && <CardMedia className={classes.promotionBadge} image={promotionBadge} />
          }
          <CardMedia
            className={classes.media}
            image={serviceUrl({ service: 'STATIC', path: get(item, 'imgUrl'), thumbnail: true })}
            title={get(item, 'name')}
          />
        </CardActionArea>
        <CardContent>
          <Typography noWrap gutterBottom variant="body1" component="h2">
            {get(item, 'name')}
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary" component="p">
            {get(item, 'description')}
          </Typography>
        </CardContent>
        <Box my={2}>
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle2" align="center">
                <StorefrontOutlinedIcon className={classes.icon} />
                {getQuantityText(t, item)}
              </Typography>
            </Grid>
            <Grid item xs>
              {
                (price || price === 0) && (
                  <Typography variant="subtitle2" align="center">
                    <LocalOfferOutlined className={classes.icon} />
                    {formatPrice(price, currency)}
                  </Typography>
                )
              }
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default CatalogItem;

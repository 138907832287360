/* eslint-disable import/prefer-default-export */
import strings from '../strings';

export const translation = {
  [strings.defaultBio]: '',
  [strings.search]: 'Търсене',
  [strings.showingItems]: 'Показват се',
  [strings.outOf]: 'от общо',
  [strings.next]: 'Следваща',
  [strings.back]: 'Предишна',
  [strings.hostedByOnstock]: 'Предоставен от Onstock.',
  [strings.loadingCatalog]: 'Каталогът се зарежда.',
  [strings.error]: 'Грешка',
  [strings.notFound]: 'Липсващ каталог',
  [strings.requestedCatalogDoesNotExist]:
    'Каталог с такъв идентификатор не съществува.',
  [strings.somethingWentWrong]:
    'Изникна грешка при обработка на заявката ви. Моля, опитайте отново по-късно.',
  [strings.available]: 'В наличност',
  [strings.showAll]: 'Покажи всички',
  [strings.sortByName]: 'А-Я',
  [strings.sortByDate]: 'Най-Нови',
  [strings.sortBy]: 'СОРТИРАНЕ',
};

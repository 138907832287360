import {
  Box,
  Button,
  ButtonGroup,
  Typography,
} from '@material-ui/core';

import React from 'react';
import strings from 'common/strings';

export default props => {
  const {
    t,
    currentPage,
    totalItems,
    pageSize,
    setPage,
  } = props;
  const isLastPage = currentPage * pageSize >= totalItems;
  return (
    <Box
      width={1}
      py={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box textAlign="center">
        <Typography variant="body2" color="textSecondary">
          {t(strings.showingItems)}
          &nbsp;
          {(currentPage - 1) * pageSize + 1}
          -
          {Math.min((currentPage * pageSize), totalItems)}
          &nbsp;
          {t(strings.outOf)}
          &nbsp;
          {totalItems}
          .
        </Typography>
      </Box>
      <br />
      <Box>
        <ButtonGroup
          color="primary"
          aria-label="small button group"
        >
          <Button
            disabled={currentPage === 1}
            href="#start"
            onClick={() => setPage(currentPage - 1)}
          >
            {t(strings.back)}
          </Button>
          <Button
            disabled={isLastPage}
            href="#start"
            onClick={() => setPage(currentPage + 1)}
          >
            {t(strings.next)}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

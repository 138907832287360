/* eslint-disable import/prefer-default-export */
import strings from '../strings';

export const translation = {
  [strings.defaultBio]: "I'm using Onstock catalogs.",
  [strings.search]: 'Search',
  [strings.showingItems]: 'Showing items',
  [strings.outOf]: 'out of',
  [strings.next]: 'next',
  [strings.back]: 'back',
  [strings.hostedByOnstock]: 'Hosted by Onstock.',
  [strings.loadingCatalog]: 'Loading catalog',
  [strings.error]: 'An error ocurred.',
  [strings.notFound]: 'Not found',
  [strings.requestedCatalogDoesNotExist]: 'Requested catalog does not exist.',
  [strings.somethingWentWrong]: 'Something went wrong. Please try again later.',
  [strings.available]: 'Available',
  [strings.showAll]: 'Show all',
  [strings.sortByName]: 'A-Z',
  [strings.sortByDate]: 'Newest',
  [strings.sortBy]: 'SORT BY',
};

import defaultImg from '../img/default-img.png';

const services = {
  API: process.env.REACT_APP_API_URL,
  STATIC: process.env.REACT_APP_STATIC_URL,
};

/**
 * Build a URL given a service and URI
 * @param {object} options
 * @param {'API', 'STATIC'} options.service - service tag
 * @param {string} options.path - URI path
 * @param {bool} options.useDefaultImg - use default image
 * @param {bool} options.thumbnail
 */
const serviceUrl = ({
  service, path, thumbnail, useDefaultImg = true,
}) => {
  if (service === 'STATIC' && !path) {
    return useDefaultImg ? defaultImg : null;
  }
  let postfix = '';
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  if (service === 'STATIC') {
    postfix = thumbnail ? '?w=400&h=300' : '';
  }
  const url = `${services[service]}/${cleanPath}${postfix}`;
  return url;
};

export default serviceUrl;

import React from 'react';
import { Box, Typography } from '@material-ui/core';

const ErrorPage = ({
  t, errorKey, descriptionKey, icon,
}) => (
  <Box
    width={1}
    height={1}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    {icon}
    <Typography variant="h4" color="textSecondary">
      {t(errorKey)}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {t(descriptionKey)}
    </Typography>
  </Box>
);

export default ErrorPage;

import 'react-image-lightbox/style.css';

import React, { useCallback, useEffect, useState } from 'react';

import CatalogFooter from 'components/CatalogFooter';
import CatalogGrid from 'components/CatalogGrid';
import CatalogHeader from 'components/CatalogHeader';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorPage from 'pages/ErrorPage';
import FullPageLoader from 'components/FullPageLoader';
import ItemFilter from 'components/ItemFilter';
import Lightbox from 'react-image-lightbox';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import PageSwitcher from 'components/PageSwitcher';
import ScrollUpButton from 'components/ScrollUpButton';
import _ from 'lodash';
import axios from 'axios';
import serviceUrl from 'util/url';
import strings from 'common/strings';

const PAGE_SIZE = 24;

const CatalogPage = props => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [catalog, setCatalog] = useState(null);
  const [error, setError] = useState(null);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const { t } = props;

  const handlePageChange = useCallback(
    updatePage => {
      const catalogId = _.get(props, 'match.params.shortCode');
      const skip = PAGE_SIZE * (updatePage - 1);
      let path = `/catalogs/${catalogId}?take=${PAGE_SIZE}&skip=${skip}&orderBy=${sortBy}`;
      if (filter) {
        path += `&q=${filter}`;
      }

      axios
        .get(
          serviceUrl({
            service: 'API',
            path,
          }),
        )
        .then(({ data: updateCatalog }) => {
          document.title = _.get(updateCatalog, 'firm.name');
          return updateCatalog;
        })
        .then(updateCatalog => {
          setCatalog(updateCatalog);
          setPage(updatePage);
        })
        .catch(err => setError({ error: err.response }));
    },
    [page, filter, sortBy],
  );

  const handleFilterChange = useCallback(
    q => {
      setFilter(q);
      const catalogId = _.get(props, 'match.params.shortCode');
      axios
        .get(
          serviceUrl({
            service: 'API',
            path: `/catalogs/${catalogId}?take=${PAGE_SIZE}&q=${q}&orderBy=${sortBy}`,
          }),
        )
        .then(({ data: filteredCatalog }) => {
          document.title = _.get(filteredCatalog, 'firm.name');
          return filteredCatalog;
        })
        .then(filteredCatalog => {
          setFilter(q);
          setPage(1);
          setCatalog(filteredCatalog);
        })
        .catch(err => setError({ error: err.response }));
    },
    [filter, sortBy],
  );

  useEffect(() => {
    handlePageChange(1);
  }, []);

  useEffect(() => {
    handleFilterChange(filter);
  }, [sortBy]);

  if (error) {
    return error.status === 404 ? (
      <NotFoundPage />
    ) : (
      <ErrorPage
        errorKey={strings.error}
        descriptionKey={strings.somethingWentWrong}
        icon={<ErrorOutlineIcon color="disabled" fontSize="large" />}
      />
    );
  }

  if (!catalog) {
    return <FullPageLoader message={t(strings.loadingCatalog)} />;
  }

  const displayItems = _.get(catalog, 'catalogStocks', []);

  return (
    <>
      <CatalogHeader firm={_.get(catalog, 'firm')} />
      <ItemFilter
        onChange={handleFilterChange}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <CatalogGrid
        items={displayItems}
        currency={_.get(catalog, 'firm.currency.name')}
        showLightbox={image => {
          setIsLightboxVisible(true);
          setLightboxImage(image);
        }}
      />
      {isLightboxVisible && (
        <Lightbox
          mainSrc={serviceUrl({ service: 'STATIC', path: lightboxImage })}
          onCloseRequest={() => {
            setIsLightboxVisible(false);
            setLightboxImage(null);
          }}
        />
      )}
      <ScrollUpButton />
      <PageSwitcher
        pageSize={PAGE_SIZE}
        totalItems={_.get(catalog, 'totalCount', 0)}
        currentPage={page}
        setPage={handlePageChange}
      />
      <CatalogFooter />
    </>
  );
};

export default CatalogPage;

export default {
  defaultBio: 'defaultBio',
  search: 'search',
  showingItems: 'showingItems',
  outOf: 'outOf',
  next: 'next',
  back: 'back',
  hostedByOnstock: 'hostedByOnstock',
  loadingCatalog: 'loadingCatalog',
  error: 'error',
  notFound: 'notFound',
  requestedCatalogDoesNotExist: 'requestedCatalogDoesNotExist',
  somethingWentWrong: 'somethingWentWrong',
  available: 'available',
  showAll: 'showAll',
  sortByName: 'sortByName',
  sortByDate: 'sortByDate',
  sortBy: 'sortBy',
};
